import React from "react";
import "./SubBanner.css";
import { useTranslation } from "react-i18next";
import Title from "../../../common/Title";

export default function SubBanner({data}) {
  const { t } = useTranslation();
  return (
    <section className="sub-banner" >
      <div className="container">
      <Title data={t(data.title)} />
          <p className="sub-banner__description">
            {t(data.description)}
          </p>
      </div>  
    </section>
  );
}
