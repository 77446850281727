import React from "react";
// import DetailEntryButton from "../sections/recruit/common/DetailEntryButton";
import RecruitDetail from "../sections/recruit/common/RecruitDetail";

export default function BrasilLeader({ data }) {
  return (
    <React.Fragment>
      <RecruitDetail data={data.JobDetails} />
      {/* <DetailEntryButton id="recruit-detail__entry" path="/recruit/entry" /> */}
    </React.Fragment>
  );
}
