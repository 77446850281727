import React from "react";
import "./RecruitHomeReason.css";
import { useTranslation } from "react-i18next";
import Image from "../../../common/Image";
import Title from "../../../common/Title";

export default function RecruitHomeReason({ data }) {
  const { t } = useTranslation();
  return (
    <section className="recruit-home-reason">
      <div className="container">
        <Title data={t(data.title)} />
        <div className="recruit-home-reason__items">
          {data.items.map((item, index) => (
            <div key={index} className="recruit-home-reason__card">
              <Image
                className="recruit-home-reason__card-image"
                {...item.image}
              />
              <div className="recruit-home-reason__card-content">
                <h3 className="recruit-home-reason__card-content-title">{t(item.title)}</h3>
                <p>{t(item.description)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
