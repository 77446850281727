import React from "react";
import RecruitHomeHeader from "../sections/recruit/home/RecruitHomeHeader";
import SubBanner from "../sections/recruit/home/SubBanner";
import RecruitHomeReason from "../sections/recruit/home/RecruitHomeReason";
import RecruitHomeTargetingPersons from "../sections/recruit/home/RecruitHomeTargetingPersons";


export default function RecruitHome({ data }) {
  return (
    <React.Fragment>
      <RecruitHomeHeader data={data.RecruitHomeHeader} />
      <SubBanner data={data.SubBanner}/>
      <RecruitHomeReason data={data.RecruitHomeReason} />
      <RecruitHomeTargetingPersons data={data.RecruitHomeTargetingPersons} />
    </React.Fragment>
  );
}
