import React from "react";
import TargetPersonCard from "./TargetPersonCard";
import JobDescriptionCard from "./JobDescriptionCard";
import JobRequirementCard from "./JobRequirementCard";
import JobEntryCard from "./JobEntryCard";
import Policy from "./Policy";
import "./RecruitDetail.css";
// import { useTranslation } from "react-i18next";

export default function RecruitDetail({ data }) {
  // const {t} = useTranslation();
  return (
    <section className="recruit-detail">
      {data.JobDescription ? (
        <JobDescriptionCard data={data.JobDescription} />
      ) : null}
      {data.JobRequirement ? (
        <JobRequirementCard data={data.JobRequirement} />
      ) : null}
      {data.TargetPerson ? <TargetPersonCard data={data.TargetPerson} /> : null}
      {data.Policy ? <Policy data={data.Policy} /> : null}
      {data.JobEntry ? <JobEntryCard data={data.JobEntry} /> : null}
    </section>
  );
}
