import React from "react";
import { graphql } from "gatsby";

import RecruitHome from "../components/pages/RecruitHome";
import Layout from "../components/layout";
import RecruitDetail from "../components/pages/RecruitDetail";
import RecruitEntry from "../components/pages/Entry";

//Home page
const HomePage = ({ data }) => (
  <Layout>
    <RecruitHome data={data.recruitHomeJson} />
    <RecruitDetail data={data.recruitDetailJson} />
    <RecruitEntry data={data.recruitEntryJson} />
  </Layout>
);

export default HomePage;

export const query = graphql`
  {
    recruitHomeJson {
      RecruitHomeHeader {
        title
        description
        image {
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
      SubBanner {
        title
        description
      }
      RecruitHomeReason {
        items {
          description
          title
          image {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
        title
      }
      RecruitHomeTargetingPersons {
        title
        items {
          description
          title
        }
        images {
          jp {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
          br {
            alt
            src {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
          }
        }
        languages
      }
    }

    recruitDetailJson {
      JobDetails {
        JobDescription {
          descriptions
          title
        }
        JobRequirement {
          items {
            descriptions
            subtitle
          }
          title
        }
        Policy {
          detail {
            description
            icon {
              alt
              type
            }
            title
          }
          title
        }
        TargetPerson {
          descriptions
          title
        }
        JobEntry {
          descriptions
          title
        }
      }
    }

    recruitEntryJson {
      EntryForm {
        action
        submit
        title
        items {
          choices
          is_required
          label
          name
          placeholder
          type
        }
      }
    }
  }
`;
